import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import { Helmet } from "react-helmet"

export default function Template({ data, pageContext }) {

  const bannerUrl = "/home-banner.jpg";

  return ( 
    <Layout title="We strive to optimize your process serving experience." description="Committed to providing you with all your process serving needs throughout Saskatchewan.">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prairie Process Serving</title>
      </Helmet>
      <Banner title="We strive to optimize your process serving experience." imageUrl={bannerUrl}/>
      <section className="section announcement">
        <div className="container"> 
          <img className="icon" alt="information icon" src="assets/info-icon.svg" />
          <p className="">There will be no in-person appointments at this time and the office is closed to the public. Should you have documents for service we ask that you email or mail them to our office. Alternatively, if you are located in Saskatoon or Regina we can pick up documents for a pick-up fee of $5.00. Please call <a className="transition" href="tel:+3062609861">306.260.9861</a> to make arrangements.</p>
        </div>
      </section>
      <section className="section process">
        <div className="container columns is-vcentered"> 
          <div className="column is-half content">
            <p>Prairie Process Serving is a Saskatchewan based company specializing in process serving and is committed to providing you with all of your process serving needs throughout the Province.</p>
            <p>We currently have process servers located in the following cities/towns:</p>
            <ul style={{"listStyle": "disc"}}>
              <li>Saskatoon</li>
              <li>Regina</li>
              <li>Prince Albert</li>
              <li>Asquith</li>
              <li>Battleford</li>
              <li>Swift Current</li>
              <li>Kindersley</li>
              <li>Estevan</li>
              <li>Yorkton</li>
              <li>Moose Jaw</li>
            </ul>
            <p>We are continuously seeking out process servers, so please feel free to email our office at any time for an updated process serving list! Should you need to courier documents please call or email us for an alternative address. </p>
          </div>
          <img className="column is-half" alt="Bright office with wooden clock on the wall." src="assets/about-01.jpg" />
        </div>
      </section>
      <section className="section process-02 no-top-padding">
        <div className="container columns is-vcentered">
          <img className="column is-half" alt="A side table made out of wood, two shelves, both holding a few books." src="assets/about-02.jpg" />
          <div className="column is-half content">
            <h3>Meet the Team at PPS!</h3>
            <p>PPS has a wide range of experience with respect to legal proceedings in Saskatchewan and in what manner documents need to be served under the rules of The Courts. Kelly Pegg (Owner and Founder of PPS) has been in the legal industry for nearly 10 years and has an extensive background in process serving, debt collection and foreclosure proceedings. Kelly has further experience in many other different areas of law such as, family, general litigation, real estate, corporate and wills and estates. This is greatly beneficial when it comes to analyzing what the client needs.</p>
          </div>
        </div>
        <div className="container columns">
          <div className="column is-half">
             <p>All of the process servers with Prairie Process Serving are vetted and have a wide range of experience. We have servers who are legal assistants, retired RCMP officers or correctional officers (to name a few). At PPS we always ensure to hire process servers who have a background to some degree related to the legal industry. All of our process servers are screened before being hired and are required to provide us with a clean criminal record check. We also provide in person training and have a policy manual for our process servers to ensure optimal results.</p>     
          </div>
          <div className="column is-half">
            <p>Our team can provide you with all your process serving needs, simply <a className="link transition" href="mailto:service@prairieprocessserving.com">email us</a> for a quote today!</p>
          </div>
        </div>
      </section>
      <section className="section social" style={{backgroundImage: "url(/assets/social-banner.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}>
        <div className="container">
          <h2 className="has-text-centered" style={{"margin": "0 auto"}}><a className="link transition" target="_blank" href="https://www.facebook.com/prairieprocessserving">Follow us on Facebook and keep up with everything new with PPS!</a></h2>
        </div>
      </section>
      <section className="section steps">
        <div className="container columns">
          <div className="column is-half">
            <h2>Feel Safe and Trusted when Choosing PPS</h2>
          </div>
          <div className="column is-half">
            <p>At PPS we ensure to keep your information and your clients information protected at all times. We shred all physical documents after an appropriate amount of time has passed and we delete our emails regularly. </p>
          </div>
        </div>
      </section>
      <section className="section steps no-top-padding">
        <div className="container columns">
          <h2 className="column is-half">We make process serving easy:</h2>
          <div className="column is-half">
            <div class="icon-list-item">
              <img className="" alt="number one icon" src="assets/one-icon.svg" />
              <a href="assets/process-serving-instruction-sheet.docx" download><h3>Download and fill out this form</h3></a>
            </div>
            <div class="icon-list-item">
              <img className="" alt="number two icon" src="assets/two-icon.svg" />
              <a className="transition" href="mailto:service@prairieprocessserving.com"><h3 className="transition">Email it back to us</h3></a>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="section services">
        <div className="container"> 
          <h2>Services That We Offer:</h2>
          <div class="icon-list-item">
            <img className="" alt="checkmark icon" src="assets/checkmark-icon.svg" />
            <h3>Process Serving</h3>
          </div>
          <div class="icon-list-item">
            <img className="" alt="checkmark icon" src="assets/checkmark-icon.svg" />
            <h3>Litigation searches</h3>
              <p>(Saskatchewan Personal Property Registration [SPPR], Judgment, Court Action, Corporate Registry and Land Title Searches)</p>            
          </div>
          <div class="icon-list-item">
            <img className="" alt="checkmark icon" src="assets/checkmark-icon.svg" />
            <h3>Assistance with surrogacy matters (for law firms)</h3>
          </div>
          <div class="icon-list-item">
            <img className="" alt="checkmark icon" src="assets/checkmark-icon.svg" />
            <div>
              <h3>Notary Public services</h3>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
      }
    }
  }
`