import React from "react"
import classNames from 'classnames';

export default function Banner(props) {
  return (
    <section className={`section banner has-text-left ${props.extraClasses ? props.extraClasses : ""}`} style={{backgroundImage: `url(${props.imageUrl})`}}>
      <div className="container" style={{height: "100%", position:"relative", zIndex:"3"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
          <div>
            <h1 className="title is-1 is-size-3-mobile has-text-white">{props.title}</h1>
            <a className="button is-left is-outlined is-white transition" href="mailto:service@prairieprocessserving.com">Contact us today!</a>   
          </div>
        </div>
      </div>
    </section>
  )
}